/* -------------NavBar----------- */

.navBar {
  background-color: #f3f2ff;
}

.linkColor {
  color: #02073e;
  padding: auto 20px;
  margin-left: 30px;
  cursor: pointer;
  font-family: "Montserrat", Sans-serif;
}

.linkColor .active {
  color: #02073e;
  border-radius: 5px;
  background-color: #807479 !important;
}

.active,
.linkColor:hover {
  color: #FFF;
  border-radius: 5px;
  background-color: #4700c3;
  /* background-color: #807479; */
}

.linkColor:focus {
  color: #02073e;
}

.header-logo-show {
  visibility: visible;
}
.header-logo-hide {
  visibility: hidden;
}
/* ---------------Home-------------- */
/* ----------------------------------- */
.outer-wrapper {
  background-color: #f3f2ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat", Sans-serif;
}

.iquantumflows {
  max-width: 1200px;
  background-color: #02010100;
  margin-top: 100px;
  width: 100%;
}
.upper-container {
  text-align: center;
}
.main-heading {
  margin-top: 30px;
  font-family: "Montserrat", Sans-serif;
  font-size: 45px;
  font-weight: 700;
  text-transform: capitalize;
  /* line-height: 85px; */
  animation-name: example;
  animation-duration: 20s;
  animation-iteration-count: infinite;
}



@keyframes example {
  0% {
    color: #8701fc;
  }
  /* 25%  {color: yellow;} */
  50% {
    color: #4c2a89;
  }
  100% {
    color: #4700c3;
  }
}

.second-heading {
  color: #02073e;
  margin-top: 10px;
  font-family: "Montserrat", Sans-serif;
  font-size: 35px;
  font-weight: 500;
}

.third-heading {
  color: #02073e;
  margin-top: 100px;
  font-family: "Montserrat", Sans-serif;
  font-size: 35px;
  font-weight: 500;
  text-align: center;
}

.doc-btn {
  background: transparent;
  color: #ffffff;
  border-radius: 50px;
  border: 2px solid #ffffff;
  padding: 10px 30px;
  margin-top: 30px;
  font-size: 15px;
  font-weight: 600;
}

.doc-btn:hover {
  border: 2px solid #b9c3e8;
  color: #b9c3e8;
}
.lower-container {
  text-align: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.partical-card-heading {
  color: #02073e;
  font-family: "Montserrat", Sans-serif;
  font-size: 20px;
  font-weight: 700;
}
.partical-card-second-heading {
  color: #02073e;
  font-size: 17px;
  margin-top: 20px;
  line-height: 24px;
  font-weight: 500;
  text-align: justify;
}
.iquantumflows-card {
  background-color: #ebe2fd;
  width: 32%;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  /* margin: 0px 12.5px 0px 0px; */
  padding: 25px;
  border-radius: 10px;
  text-align: left;
  margin-top: 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

/* -----------------protocol------------------ */

.iquantumflows-protocol {
  max-width: 1200px;
  margin-top: 100px;
  width: 100%;
  /* background: linear-gradient(
    225deg,
    rgba(247, 146, 86, 1) 0%,
    rgba(52, 39, 75, 1) 50%,
    rgba(12, 26, 48, 1) 100%
  ); */
  background-image: linear-gradient(to right, #4700c3, #8701fc) !important;
  border-radius: 10px;
  padding: 70px 0px 70px 70px;
}

.protocol-heading {
  font-family: "Montserrat", Sans-serif;
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;
  color: #FFF;
}

.protocol-img {
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
}

.protocol-img img {
  width: 145%;
}

.iquantumflows-overview {
  max-width: 1200px;
  margin-bottom: 30px;
  width: 100%;
  background: #1f2235;
  border-radius: 10px;
  padding: 70px;
}

.protocol-heading-font {
  font-size: 22px;
}

.iquantumflows-overview-after {
  max-width: 1200px;
  width: 100%;
  display: flex;
  margin-bottom: 100px;
  margin-top: 100px;
}

.wallet-support,
.unified-design {
  width: 35%;
  background: #4700c3;
  margin-right: 20px;
  padding: 50px;
  border-radius: 10px;
}

.wallet-support-link {
  color: #3c4a58;
  text-decoration: none;
}
.unified-design {
  width: 65%;
  margin-right: 0;
}

.coin-image {
  background-color: #2f3353;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 5px;
}
.wallet-support-third-heading {
  color: #02073e;
  font-family: "Montserrat", Sans-serif;
  font-size: 14px;
  font-weight: 500;
}

/* -------------------Team---------------- */
.team {
  max-width: 1200px;
  width: 100%;
  /* display: flex; */
  /* margin-bottom: 150px; */
}

.team-card {
  width: 49%;
  background-color: #272a3e4d;
  padding: 20px;
  border-radius: 10px;
}

.team-founder {
  color: rgb(247, 146, 86);
  font-family: "Montserrat", Sans-serif;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 15px;
}

.team-member {
  width: 32%;
  background-color: #ebe2fd;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-right: 15px;
}
.team-advisor {
  width: 32%;
  border: 2px solid #272a3e;
  padding: 25px;
  border-radius: 10px;
  margin-right: 20px;
}

/* -----------------Connect------------------ */

.connect {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.upper-learn {
  width: 50%;
  margin: auto;
  text-align: center;
}
.upper-learn h5 {
  font-size: 20px;
}

.lower-learn {
  width: 100%;
  background-color: #1f2235;
  margin-top: 30px;
  margin-bottom: 100px;
  padding: 75px 75px 75px 75px;
}
.learn-card {
  width: 32%;
  background-color: #272a3e;
  border-radius: 10px;
  border: 1px solid #272a3e;
  margin: 5px;
  padding: 25px;
}

.connect-icon {
  background-color: #5f72d1;
  color: #ffffffb0;
  border-color: #ffffffb0;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 40px;
}

.connect-icon:hover {
  transform: translateY(-8px);
  color: #d8226e;
  /* transform: scale(1.1); */
}
.invester {
  background-color: #1f2235;
  padding: 25px;
  margin: 5px;
  border-radius: 10px;
  width: 24%;
  text-align: center;
}

.invester img:hover {
  transform: scale(1.1);
}

.angel-invester {
  width: 24%;
  background-color: #1f223559;
  border: 2px solid #22263b;
  margin: 5px;
  padding: 25px;
  border-radius: 10px;
  text-align: center;
}
.angel-invester a {
  text-decoration: none;
  font-size: 20px;
}

.angel-invester h3 {
  font-size: 20px;
  line-height: 24px;
}

.angel-invester p {
  font-size: 15px;
}

/* ----------------------StayTouch---------------------- */

.stay-touch {
  width: 100%;
  max-width: 900px;
  /* background-color: #1f2235; */
  background-image: linear-gradient(to right, #4700c3, #8701fc) !important;
  padding: 50px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 70px;
}

.input-form {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.input-form input {
  color: #fff;
  background: transparent;
  border: 1px solid #fff;
  font-size: 1rem;
  padding: 10px;
  border-radius: 10px;
  margin-right: 20px;
  /* margin-bottom: 20px; */
}

::placeholder {
  color: #fff;
  opacity: 1;
}

/* .input-form input:focus-visible{
  border: none;
} */

.back-btn {
  color: #ffffff;
  border: none;
  font-family: "Montserrat", Sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-transform: none;
  border-radius: 48px;
  padding: 10px 30px 010px 30px;
}

.back-btn:hover {
  background-color: #ce0057;
  color: #ffffff;
}

.stay-touch p {
  color: #fff;
  font-family: "Montserrat", Sans-serif;
  font-size: 13px;
  font-weight: 300;
  font-style: italic;
  line-height: 24px;
}

/* ----------------------Footer---------------------------
------------------------------------------------------- */

.footer {
  width: 100%;
  background-color: #f3f2ff;
  padding: 30px;
  color: #02073e;
}

.footer-div {
  max-width: 1600px;
  width: 100%;
  margin: auto;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.copyright {
  width: 25%;
}

.footer-para,
.footer-copyright-link {
  color: #02073e;
  font-family: "Montserrat", Sans-serif;
  font-size: 12px;
  line-height: 18px;
}

.footer-copyright-link {
  text-decoration: none;
  font-weight: bolder;
}

.footer-copyright-link:hover {
  color: #02073e;
}

.footer-links {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footer-link {
  color: #02073e;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Montserrat", Sans-serif;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
}

.footer-icon {
  width: 25%;
}
.footer-link-social {
  color: #ffffff;
}

.footer-link-social:hover,
.footer-link:hover {
  color: #4700c3;
}
/* ------------------social-icon------------------------- */

.social-icon {
  color: #bfbfbf;
  margin-right: 20px;
  font-size: 16px;
}

.social-icon:hover {
  color: rgb(247, 146, 86);
}
/* -----------------------Responsive--------------------
---------------------------------------------------- */

@media only screen and (max-width: 768px) {
  .header-logo-show,
  .header-logo-hide {
    display: none;
  }
  .main-heading {
    font-size: 31px;
  }
  .second-heading
  /* , .protocol-heading */ {
    font-size: 25px;
  }
  .lower-container {
    flex-direction: column;
  }
  .iquantumflows-card {
    width: 100%;
    margin-bottom: 20px;
  }
  .iquantumflows-protocol {
    width: 95%;
    margin-top: 60px;
    padding: 0 2px;
  }
  .protocol-img img {
    width: 100%;
  }
  .iquantumflows-overview,
  .wallet-support,
  .unified-design {
    padding: 30px 20px;
  }
  .iquantumflows-overview-after {
    flex-direction: column;
    margin-bottom: 70px;
  }
  .wallet-support,
  .unified-design {
    width: 100%;
  }
  .team-card {
    width: 95%;
    margin-bottom: 20px;
  }
  .team-member {
    width: 45%;
  }
  .team-advisor {
    width: 45%;
    margin-bottom: 20px;
  }
  /* -----------------Connect--------------- */
  /* .upper-learn,
  .learn-card,
  .invester,
  .angel-invester{
    width: 95%;
  } */
  .invester,
  .angel-invester {
    width: 30%;
  }
  /* ---------------------Stay Touch------------- */

  .input-form input {
    margin-bottom: 20px;
    /* margin-right: 0px; */
  }
  /* ---------------footer--------------- */

  .footer-div {
    flex-direction: column;
  }

  .copyright {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .footer-links {
    width: 100%;
    margin-bottom: 30px;
  }

  .footer-icon {
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 500px) {
  .upper-learn,
  .learn-card,
  .invester,
  .angel-invester,
  .team-advisor,
  .team-member {
    width: 95%;
  }
  .input-form input {
    margin-right: 0px;
  }
  .footer-link {
    padding-left: 0px;
  }
  .protocol-heading {
    font-size: 30px;
  }
}
